.add-items-form{
  .input-row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing;
    padding: $spacing;
    margin-top: $spacing;

    input,
    select{
      box-shadow: none;

      &:focus{
        border-color: $black;
      }
    }

    .full-width{
      grid-column: span 3;
    }

    .section-title{
      background-color: rgba($color: $text-gray, $alpha: 0.1);
      padding: calc($spacing / 2) $spacing;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .websites-form-container{
    padding: $spacing;
    gap: $spacing;
  }
  .websites-container{
    padding: calc($spacing / 2);
    gap: calc($spacing / 2);

    .website-item{
      gap: calc($spacing / 2);
      padding: calc($spacing / 2);
    }
  }

  .regions-container{
    height: 250px;
    overflow: auto;
    border: 1px solid #DEE2E6;
    border-radius: 0.375rem;

    .region-place-name{
      height: 40px;
      background-color: $primary-color;
      color: $white;
      padding: 0 calc($spacing / 2);
    }

    .region-label{
      height: 40px;
      padding: 0 calc($spacing / 2);
      font-size: 14px;
      gap: calc($spacing / 2);
      cursor: pointer;
      position: relative;

      &::after{
        position: absolute;
        content: '';
        height: 100%;
        width: 2px;
        top: 0;
        left: 0;
        background-color: $primary-color;
        opacity: 0;
      }

      &.checked{
        background-color: rgba($color: $text-gray, $alpha: 0.1);
        &::after{
          opacity: 1;
        }
      }

      &:hover{
        background-color: rgba($color: $primary-color, $alpha: 0.1);
      }
    }
  }

  .custom-date-input{
    height: 38px;
    border: 1px solid #DEE2E6;
    border-radius: 0.375rem;
    width: 100%;
    padding: .375rem .75rem;
    transition: all 0.15s ease-in-out;
    &:hover{
      border-color: $black;
    }
    .icon{
      width: 20px;
      height: auto;
      cursor: pointer;
    }
  }

  .react-datepicker-wrapper{
    width: 100%;
  }
}