body {
  font-family: "Poppins", sans-serif;
  background-color: $body-bg;
}

.source-code-pro {
  font-family: "Source Code Pro", monospace;
}

.background-primary {
  background-color: $primary-color;
}

.btn-outline-primary{
  border-color: $primary-color !important;
  color: $primary-color !important;

  &:hover{
    color: $white !important;
  }
}

.txt-primary {
  color: $primary-color;
}

.no-box-shadow-input {
  &:focus {
    box-shadow: none;
    border-color: $black;
  }
}

.site-wrapper {
  min-height: 100vh;
  padding-top: $header-height;
}

.site-container {
  padding: $spacing;
}

.paper-title {
  font-size: 20px;
}

.btn.background-primary {
  background-color: $primary-color !important;

  &.login-btn{
    &:disabled{
      opacity: 1;
      background-color: $black !important;
    }
  }
}

.table-container {
  margin-top: $spacing;

  .site-table {

    th {
      background-color: $primary-color;
      color: $white;
      padding: calc($spacing / 2) $spacing;
    }

    td {
      padding: calc($spacing / 2) $spacing;
    }

  }
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.switch {

  cursor: pointer;

  span {
    display: inline-block;
    height: $spacing;
    width: calc($spacing * 2);
    background-color: rgba($color: $text-gray, $alpha: 0.6);
    border-radius: 10px;
    position: relative;
    transition: all 0.3s;

    &::after {
      position: absolute;
      content: '';
      height: calc($spacing / 2);
      width: calc($spacing / 2);
      background-color: $white;
      border-radius: 50%;
      top: calc($spacing / 4);
      left: calc($spacing / 4);
      transition: all 0.3s;
    }
  }

  input:checked {
    &+span {
      background-color: $primary-color;

      &::after {
        left: calc($spacing + $spacing / 4);
      }
    }
  }
}

.required {
  position: relative;

  &::after {
    content: '*';
    color: #ff5233;
  }
}

.icon-btn {

  height: calc($spacing * 1.5);
  width: calc($spacing * 1.5);
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 16px;
    height: auto;

    path {
      fill: $white;
    }
  }
}

.snackbar {
  position: fixed;
  top: $spacing;
  right: $spacing;
  padding: calc($spacing / 2) $spacing;
  z-index: 2100;
  border-radius: calc($spacing / 2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.react-select{
  .react-select__control{
    box-shadow: none !important;
    border-radius: 0.375rem;

    &:hover,
    &--is-focused{
      border-color: $black;
    }
  }

  &__indicator-separator{
    display: none;
  }
}

.react-datepicker{

  font-family: "Poppins", sans-serif !important;

  .react-datepicker__header{
    background-color: $primary-color;

    .react-datepicker__current-month{
      color: $white
    }
    .react-datepicker__day-names{
      .react-datepicker__day-name{
        color: $white;
      }
    }
  }

  .react-datepicker__month{
    .react-datepicker__day--selected{
      background-color: $primary-color;
    }
  }

  .custom-datepicker-header{
    padding-left: calc($spacing / 2);
    color: $white;

    select{
      height: 25px;
      border: none;
      margin-right: calc($spacing / 2);

      &:focus{
        outline: none;
      }
    }
    .arrow-container{
      svg{
        cursor: pointer;
        path{
          fill: $white;
        }
      }
    }
  }
}

.react-datepicker-popper{
  .react-datepicker__triangle{
    fill: $primary-color !important;

    path{
      stroke: $primary-color;
    }
  }
}

.modal{
  background-color: rgba($color: $black, $alpha: 0.5);
}