.login-container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  // background-image: url(../assets/images/login-bg-2.jpeg);
  // background-size: cover;
  // background-repeat: no-repeat;

  .login-form-container {
    background-color: rgba($color: $white, $alpha: 1);
    // backdrop-filter: blur(20px);
    padding: calc($spacing * 2);
    width: 450px;
    border-radius: 10px;

    .login-form {
      width: 100%;

      input {
        box-shadow: none;

        &:focus {
          border-color: $black;
        }
      }

      .logo-container {
        margin-bottom: calc($spacing * 2);
      }

      .password-container {
        margin-top: $spacing;
        margin-bottom: $spacing;
      }

      .link {
        color: $black;
        font-size: 14px;
      }

      .login-btn {
        margin-bottom: $spacing;

        &:last-child {
          margin-bottom: 0;
        }

        .spinner-border {
          height: 24px;
          width: 24px;
          border-width: 2px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: calc($spacing / 2);
      }

      .description {
        font-size: 14px;
        color: rgba($color: $black, $alpha: 0.5);
      }

      .otp-input-container {
        display: grid !important;
        grid-template-columns: repeat(6, 1fr);
        gap: $spacing;


        input {
          width: 100% !important;
          height: 50px;
          box-shadow: none;
          border-radius: 5px;
          border: 1px solid #dee2e6;

          &:focus {
            border-color: $black;
            outline: none;
          }
        }
      }
    }

    .loader-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $white;
      z-index: 21;

      .spinner-border {
        height: 50px;
        width: 50px;
        border-width: 2px;
      }
    }
  }
}